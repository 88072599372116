export const MESSAGES = {
  COMMON: {
    SECURITY_LABEL: "Data is safe and under your control",
    SOC_SECURITY_LABEL: "SOC2 compliant",
    PRIVACY_POLICY_TITLE: "Privacy Policy",
    TERM_OF_USE_TITLE: "Terms of Use",
    ASSIGN_TITLE: "Assign to colleague",
    ASSIGN_TO_COLLEAGUE_TITLE:
      "One of your colleagues better able to provide information? We'll reach out directly for support to email below.",
    BUTTON_BACK: "Back",
    BUTTON_NEXT: "Next step",
    BUTTON_COMPLETE: "Complete",
    NOT_SET: "Not set",
    CLEAR: "Clear",
    PHONE_ERROR: "Please enter valid mobile phone number",
    NUMBER_ERROR: "Please enter the number",
    MIN_LENGTH_ERROR: "The field must be at least {length} characters",
    MAX_LENGTH_ERROR: "The field must be no more than {length} characters",
    REQUIRED_ERROR: "Field cannot be empty",
    URL_ERROR: "Please enter valid url",
    INVALID_DATE_ERROR: "Please enter valid date",
    OPTIONAL: "(optional)",
    LAST_UPDATED: "Last updated: {date}",
  },
  WELCOME: {
    TITLE: "Welcome to Draivn Visibility",
    SUBTITLE: "Let's make fleet insurance easier for everyone",
    BUTTON: "Get Started",
    DETAILS_SHARE:
      "Share your vehicle and telematics data safely while retaining control over your data.",
    DETAILS_COLLABORATE:
      "Collaborate with your internal colleagues to collect data efficiently.",
    DETAILS_PAPERWORK:
      "No more paperwork — share your fleet data with insurer and broker immediately.",
  },
  SUMMARY: {
    TITLE: "Review and confirm your fleet summary",
    TABNAME: "Confirmation",
    INSURANCE_TITLE: "Current insurance information",
    COMPANY_TITLE: "Company",
    VEHICLE_TITLE: "Vehicles",
    TELEMATICS_TITLE: "Telematics",
    SHARING_TITLE: "Recipients and purposes of data usage",
    SHARING_DESCRIPTION: "Obtain quote and service policy",
    AGREE_TITLE: "Terms and Conditions",
    ACKNOWLEDGEMENT_TEXT:
      "I am aware that it is my sole responsibility to inform and maintain any consent that may be required of employees and drivers about devices in vehicles and use of cell phones.",
    AGREE_TEXT: `I agree to share my data with brokers and insurers listed above.
      On my behalf brokers can invite other insurers for quotes.
      Only the  winning insurer will retain access for servicing.
      For this purpose I grant Draivn Corp. a license to load, store and share data without changing ownership or control.
      I have read and agree to terms in the`,
    LICENCE_AGREEMENT_TITLE: "license agreement",
    ASSIGNED_TO: "Assigned to {email}",
    FILE_UPLOADED: "File was uploaded",
    FILE_IMPORTED: "{count} file imported",
    FILES_IMPORTED: "{count} files imported",
    LOADED_API: "Will be imported via API",
    LOADED_MANUALLY: "Will be loaded via API",
    LOADED_SKIPED: "Skipped",
    LOADED_REQUESTED: "Supporting TSP requested",
    FIRSTNAME_TITLE: "Signer first name",
    FIRSTNAME_PLACEHOLDER: "First name",
    LASTNAME_TITLE: "Signer last name",
    LASTNAME_PLACEHOLDER: "Last name",
  },
  INSURER: {
    TITLE: "Validate current insurance information and company details",
    TABNAME: "Insurance",
    COMPANY_INFO_BLOCK_TITLE: "Company information",
    POLICY_INFO_BLOCK_TITLE: "Current insurance information",
    INSURANCE_INFO_TITLE: "Insurance information",
    INSURED_NAME_TITLE: "Company Name",
    CURRENT_INSURER_NAME_TITLE: "Insurer",
    UNIT_COUNT_TITLE: "# of power units",
    POLICY_NUMBER_TITLE: "Current policy number",
    POLICY_VALID_UNTIL_TITLE: "Expiration date",
    COMPANY_DETAILS_TITLE: "Company details",
    COMPANY_DETAILS_SUBTITLE: "Please confirm your DOT or Tax ID",
    FEIN_TITLE: "Tax ID (FEIN/BN/QEN)",
    NAICS_TITLE: "NAICS code",
    NAICS_SELECTOR_SEARCH_PLACEHOLDER: "Search",
    NAICS_SELECTOR_EMPTY_TITLE: "Select",
    DOT_TITLE: "DOT/CVOR",
    CONTACT_PERSON_PHONE_TITLE: "Contact person mobile phone number",
    CURRENT_INSURER_NAME_ERROR: "Please enter valid insurer name",
    CURRENT_INSURER_NAME_MIN_LENGHT_ERROR:
      "Insurer's name must be at least two characters",
    CURRENT_INSURER_NAME_MAX_LENGHT_ERROR:
      "Insurer's name must be maximum 100 characters",
    DOT_ERROR: "Please enter valid DOT#",
    FEIN_ERROR: "Please enter valid FEIN#",
    VALIDATORS_DOT_COMMON_MESSAGE: "DOT/CVOR# must be 2 to 9 digits",
    VALIDATORS_FEIN_COMMON_MESSAGE: "FEIN/BN requires 9 to 10 numbers",
    INSURED_NAME_PLACEHOLDER: "e.g. Trucking company",
    CURRENT_INSURER_NAME_PLACEHOLDER: "e.g. Trucking company",
    UNIT_COUNT_PLACEHOLDER: "",
    CONTACT_PERSON_PHONE_PLACEHOLDER: "e.g. +1584398349",
    DOT_PLACEHOLDER: "e.g. 123456",
    FEIN_PLACEHOLDER: "XX-XXXXXXX",
    POLICY_NUMBER_QUOTING_TITLE: "Quote number",
    POLICY_VALID_UNTIL_QOTING_TITLE: "Effective date",
  },
  TELEMATICS: {
    CHECK_CONNECTION_TITLE: "Check connection",
    CHECK_CONNECTION_ERROR_TITLE: "Connection error",
    CHECK_CONNECTION_ERROR_SUBTITLE: "Please try again later",
    CHECK_CONNECTION_ERROR_BAD_CREDS_SUBTITLE:
      "Check your credentials and try again",
    CHECK_CONNECTION_ERROR_REQEST_ERROR_SUBTITLE: "Please try again later",
    CHECK_CONNECTION_CONNECTED_STATUS: "Connected",
    CONNECTION_CONFIRM_TITLE: "Confirm connection",
    TITLE: "Connect to your Telematics",
    TABNAME: "Telematics",
    API_TITLE: "Connect via API",
    SKIP_TITLE: "Add telematics later",
    SKIP_HINT:
      "You can add telematics later in your Draivn Visibility account.",
    EMAIL_COLLEAGUE_TITLE: "Email a colleague...",
    SAMSARA_URL_HINT:
      "This is API token code you need to generate at your <b>Samsara Dashboard-> Settings-> API tokens-> Add an API token</b>.",

    TSP_SELECTOR_TITLE: "Select ELD/Telematics or Service Provider",
    TSP_SELECTOR_SEARCH_PLACEHOLDER: "Search by name",
    TSP_SELECTOR_SEARCH_NO_OPTIONS_LABEL: "No options",
    TSP_SELECTOR_EMPTY_TITLE: "Select platform",
    TSP_METHOD_SELECT_TITLE: "Choose how to provide your data",

    TOKEN_METHOD_OPTION_TITLE: "Set up the connection yourself",
    TOKEN_METHOD_OPTION_SUBTITLE: "Establish API connection manually",
    BASIC_METHOD_OPTION_TITLE: "Let us do it for you",
    BASIC_METHOD_OPTION_SUBTITLE:
      "Share your login and password and we'll set up the connection for you",

    BASE_TOKEN_TITLE: "Create an API key and check the connection",
    BASE_TOKEN_SUBTITLE_PART_1: "Refer to ",
    BASE_TOKEN_SUBTITLE_PART_2: " on how to do it.",
    BASE_TOKEN_SUBTITLE_URL_TITLE: "Detailed instructions",
    BASE_TOKEN_INPUT_TITLE: "Platform API token",

    BASE_USERNAME_TOKEN_INSTRUCTION_1_TITLE:
      "Create a new user and provide the username",
    BASE_USERNAME_TOKEN_USERNAME_TITLE: "Username",
    BASE_USERNAME_TOKEN_INSTRUCTION_2_TITLE: "Create and provide the App Token",
    BASE_USERNAME_TOKEN_TITLE: "App token/API App",

    BASE_BASIC_TITLE: "Provide your account information",
    BASE_BASIC_SUBTITLE:
      "Connection setup will be completed, and your credentials will be securely deleted.",
    BASE_BASIC_USERNAME_TITLE: "Username",
    BASE_BASIC_PASSWORD_TITLE: "Password",
    BASE_BASIC_INFO:
      "You can update your password for added account security at any point after the next 24 hours.",

    TRIMBLE_BASIC_TITLE:
      "Enter your Trimble Transportation (Peoplenet) account number/CCID and password",
    TRIMBLE_BASIC_SUBTITLE:
      "Refer to <a href='' target='_blank' >the detailed instructions</a> on how to do it.",
    TRIMBLE_BASIC_USERNAME_TITLE: "Account number/CCID",
    TRIMBLE_BASIC_PASSWORD_TITLE: "Password",
    TRIMBLE_BASIC_CID_HINT:
      "This is your account name or the ID you use when login to your Trimble platform.",

    WAYLENS_BASIC_TITLE:
      "Enter your Waylens Access Key ID and Access Key Secret",
    WAYLENS_BASIC_SUBTITLE:
      "Refer to <a href='' target='_blank' >the detailed instructions</a> on how to do it.",
    WAYLENS_BASIC_USERNAME_TITLE: "Access Key ID",
    WAYLENS_BASIC_PASSWORD_TITLE: "Access Key Secret",

    BASE_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Create a user in your {name} account",
    BASE_ACCOUNT_NAME_INSTRUCTION_1_SUBTITLE: "Detailed instructions",
    BASE_ACCOUNT_NAME_INSTRUCTION_2_TITLE: "Provide your {fieldName}",
    BASE_ACCOUNT_NAME_WO_CONF_CREDS_BANER:
      "Your service provider requires an additional agreement document to be signed. Check your email for the link to e-sign agreement to compete this step. Until this agreement is signed, your Service Provider will NOT complete the connection to share data.",
    BASE_ACCOUNT_NAME_FIELD_TITLE: "Account name",
    BASE_ACCOUNT_NAME_INSTRUCTION_CONFIRMATION_TITLE:
      "I created a user for Draivn",
    BASE_ACCOUNT_NAME_INPUT_TITLE: "Account name",
    BASE_ACCOUNT_NAME_TITLE: "Provide your Account number below",
    OMNITRACS_ACCOUNT_NAME_HINT: "The name of the account you specify",
    XRS_NAME_HINT: "The name of the account you specify",
    LYTX_ACCOUNT_NAME_HINT: "The name of the account you specify",

    BASE_DATABASE_INSTRUCTION_TITLE: "Enter your {tspName} database name below",
    BASE_DATABASE_FIELD_TITLE: "Database name",

    BASE_DOTNUMBER_INSTRUCTION_TITLE: "Provide your DOT number below",
    BASE_DOTNUMBER_FIELD_TITLE: "DOT Number",
    BASE_DOTNUMBER_BANNER_INSTRUCTION_TEXT:
      "After completing the onboarding process, you will receive an email and a notification in your {tspName} portal (within 24 hours). This will inform you that Draivn is requesting access. Please authorize this access to enable data sharing.",

    WEBFLEET_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Create a user  for Draivn in your Webfleet account",
    VERIZON_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Activate the connection to Draivn",
    WEBFLEET_ACCOUNT_NAME_HINT:
      "The name of the account you specify when you log in to your Webfleet account",
    VERIZON_ACCOUNT_NAME_HINT:
      "To find your account number, click on your account information icon in the top right corner of your Reveal account.",
    VERIZON_ACCOUNT_NAME_INSTRUCTION_2_TITLE: "Provide your Account number",
    VERIZON_ACCOUNT_NAME_TITLE: "Account number",
    VERIZON_ACCOUNT_NAME_INSTRUCTION_CONFIRMATION_TITLE:
      "I activated the connection",

    WEBFLEET_BASIC_TITLE: "Provide your account information",
    WEBFLEET_BASIC_SUBTITLE:
      "For a seamless experience, please enter your account name anf the login details below. We will securely establish the connection for you and promptly remove your credentials for added security.",
    WEBFLEET_BASIC_ACCOUNT_NAME_TITLE: "Account name",
    WEBFLEET_BASIC_USERNAME_TITLE: "Username",
    WEBFLEET_BASIC_PASSWORD_TITLE: "Password",

    NETRADYNE_POINT_1_TITLE:
      "Activate the connection with Draivn following the below instructions:",
    NETRADYNE_POINT_1_BUTTON_TITLE: "Open detailed instructions",
    NETRADYNE_POINT_2_TITLE: "Confirm the connection ticking the box below",
    NETRADYNE_POINT_2_CHECKBOX_TITLE: "I activated and enabled the connection",

    GORILLA_SAFETY_POINT_1_TITLE:
      "Activate the connection with your Gorilla Safety",
    GORILLA_SAFETY_POINT_1_BUTTON_TITLE: "Activate connection",
    GORILLA_SAFETY_POINT_1_BUTTON_DONE_TITLE: "Activated",
    GORILLA_SAFETY_POINT_2_TITLE:
      "Approve the connection in your Gorilla Safety portal",
    GORILLA_SAFETY_POINT_2_CHECKBOX_TITLE: "I approved the connection",
    GORILLA_SAFETY_POINT_3_TITLE:
      "Confirm that the connection activated clicking the button below",
    GORILLA_SAFETY_ERROR_DOT:
      "Connection error. Please make sure you have provided the correct DOT number and try again.",
    GORILLA_SAFETY_ERROR_CHECK_CONNECTION:
      "Connection error. Please make sure that you have approved the connection in the Gorilla Safety portal according to the instructions.",

    GEOTAB_BASIC_TITLE: "Enter your Geotab Platform URL, username and password",
    GEOTAB_BASIC_SUBTITLE:
      "Refer to  <a href='https://draivn.com/geotab-url' target='_blank'>the detailed instructions</a>  on how to do it.  ",
    GEOTAB_BASIC_PLATFORM_URL_TITLE: "Platform URL",
    GEOTAB_URL_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GEOTAB_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GEOTAB_DATABASE_TITLE: "Geotab",

    GPS_INSIGHT_ACCOUNT_NAME_TITLE: "Provide your Account number below",
    GPS_INSIGHT_ACCOUNT_NAME_SUBTITLE:
      "Refer to the  <a href='' target='_blank'>detailed Instructions</a> on how to find your Account number.  Need help? <a href='' target='_blank'>Contact support</a>",
    GPS_INSIGHT_ACCOUNT_NAME_ACCOUNT_NUMBER_TITLE: "Account number",
    GPS_INSIGHT_TOKEN_INSTRUCTION_1_TITLE:
      "1. Create a new user and provide the username",
    GPS_INSIGHT_TOKEN_INSTRUCTION_1_SUBTITLE:
      "Refer to <a href='https://help.gpsinsight.com/docs/user-setup/adding-a-user/' target='_blank'>the detailed instructions</a> on how to do it.",
    GPS_INSIGHT_TOKEN_USERNAME_TITLE: "Username",
    GPS_INSIGHT_TOKEN_INSTRUCTION_2_TITLE:
      "2. Create and provide the App Token ",
    GPS_INSIGHT_TOKEN_INSTRUCTION_2_SUBTITLE:
      "Refer to <a href='https://www.gpsinsight.com/apidocs/#/' target='_blank'>the detailed instructions</a> on how to do it.",
    GPS_INSIGHT_TOKEN_TITLE: "App token/API App",
    FLEET_COMPLETE_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Create a user  for Draivn in your Fleet Complete account",
    FLEET_COMPLETE_ACCOUNT_NAME_HINT:
      "The client ID of your Fleet Complete account",
    FLEET_COMPLETE_ACCOUNT_NAME_FIELD_TITLE: "Client ID",

    FLEET_LOCATE_SPIREON_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Create a user for Draivn in your FleetLocate 2.1 (Spireon) account",
    FLEET_LOCATE_SPIREON_ACCOUNT_NAME_HINT:
      "The name of the account you specify",
    FLEET_LOCATE_SPIREON_DESCRIPTION:
      "To activate the connection, we need confirmation from your account's Master user. Please provide the Master user's email address below. Your service provider will contact the Master user to approve the connection. Until this approval is received, data will not be shared.",
    FLEET_LOCATE_SPIREON_EMAIL_TITLE:
      "Your Spireon account Master user email address",

    ATNT_FLEET_COMPLETE_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Create a user  for Draivn in your AT&T (Fleet Complete) account",
    ATNT_FLEET_COMPLETE_ACCOUNT_NAME_HINT:
      "The client ID of your Fleet Complete account",

    ISAAC_ACCOUNT_NAME_TITLE: "Enter your Isaac CT number below",
    ISAAC_ACCOUNT_NAME_FIELD_TITLE: "CT number",

    FLEET_UP_ACCOUNT_NAME_INSTRUCTION_1_TITLE:
      "Create a user  for Draivn in your Fleet Up account",
    FLEET_UP_ACCOUNT_NAME_HINT: "The client ID of your FleetUp account",

    WEBFLEET_INSTRUCTIONS_URL: "https://draivn.com/webfleet",
    SAMSARA_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/connect_samsara",
    VERIZON_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/verizon_04D",
    GEOTAB_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    MOTIVE_INSTRUCTIONS_URL: "https://draivn.com/motive-api",
    GPS_INSIGHT_INSTRUCTIONS_URL:
      "https://help.gpsinsight.com/docs/user-setup/adding-a-user/",
    GPS_INSIGHT_INSTRUCTIONS_2_URL: "https://www.gpsinsight.com/apidocs/#/",
    TRIMBLE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/trimble_06D",
    MIXTELEMATICS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/mix_8D",
    EROAD_INSTRUCTIONS_URL:
      "https://help.eroad.com/nz/nz-apideveloperresources/nz-eroadapi/nz-apikey/",
    WIALON_INSTRUCTIONS_URL:
      "https://help.wialon.com/help/wialon-hosting/en/user-guide/management-system/users/user-properties/session-management#id-_Session_Management-mobile_enMobilenotifications",
    FLEET_COMPLETE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/FleetComplete_15D",
    GPS_TAB_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/123gpstabD",
    LION_EIGHT_INSTRUCTIONS_URL: "",
    LYTX_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/lytx_05D",
    OMNITRACS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/21omnitracsD",
    ISAAC_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/isaac_2",
    NETRADYNE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/netradyne_22",
    NEXTRAQ_INSTRUCTIONS_URL: "",
    ONE_STEP_GPS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/one_step_gps",
    FLEET_LOCATE_SPIREON_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/Fleetlocate_146D",
    INTELLISHIFT_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    EZ_FLEET_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    XRS_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/xrs_46D",
    ZONAR_SYSTEMS_INSTRUCTIONS_URL: "",
    ELD_RIDER_PRO_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/lion_8",
    SHELD_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/lion_8",
    XELD_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/lion_8",
    ZELD_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/lion_8",
    ORBCOMM_ELD_INSTRUCTIONS_URL: "",
    ORBCOMM_BLUE_TREE_INSTRUCTIONS_URL: "",
    VISION_TRACK_INSTRUCTIONS_URL: "",
    GORILLA_SAFETY_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    CLUTCH_ELD_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    CYNTRX_ELD_PLUS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    ELD_MANDATE_PRO_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    FLEET_LOCATE_ELD_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    PATRIOT_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/gorilla",
    SIMPLEX_ELD_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    TRACK_ON_ELD_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    TRUCKER_PATH_ELD_PRO_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/gorilla",
    WAYLENS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/waylens_10D",
    REQUEST_INSTRUCTIONS_URL: "",
    INSIGHT_MOBILE_DATA_INSTRUCTIONS_URL:
      "https://help.gpsinsight.com/docs/user-setup/adding-a-user/",
    INSIGHT_MOBILE_DATA_INSTRUCTIONS_2_URL:
      "https://www.gpsinsight.com/apidocs/#/",
    RHINO_INSTRUCTIONS_URL:
      "https://help.gpsinsight.com/docs/user-setup/adding-a-user/",
    RHINO_INSTRUCTIONS_2_URL: "https://www.gpsinsight.com/apidocs/#/",
    MONARCH_GPS_INSTRUCTIONS_URL:
      "https://help.gpsinsight.com/docs/user-setup/adding-a-user/",
    MONARCH_GPS_INSTRUCTIONS_2_URL: "https://www.gpsinsight.com/apidocs/#/",
    TOUCHTEL_GPS_INSTRUCTIONS_URL:
      "https://help.gpsinsight.com/docs/user-setup/adding-a-user/",
    TOUCHTEL_GPS_INSTRUCTIONS_2_URL: "https://www.gpsinsight.com/apidocs/#/",
    TRUCKSPY_INSTRUCTIONS_URL:
      "https://help.gpsinsight.com/docs/user-setup/adding-a-user/",
    TRUCKSPY_INSTRUCTIONS_2_URL: "https://www.gpsinsight.com/apidocs/#/",
    GPS_COMMANDER_INSTRUCTIONS_URL:
      "https://help.wialon.com/help/wialon-hosting/en/user-guide/management-system/users/user-properties/session-management#id-_Session_Management-mobile_enMobilenotifications",
    ATNT_FLEET_COMPLETE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/FleetComplete_15D",
    FLEET_UP_INSTRUCTIONS_URL: "",
    ACTSOFT_ENCORE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ADVANTAGE_ASSET_TRACKING_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ARGOS_CONNECTED_SOLUTIONS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ARI_FLEET_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ASSURED_TELEMATICS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ATNT_FLEET_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ATTRIX_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    BADGER_FLEET_SOLUTIONS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    BAR_CODING_CANADA_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    BLUEARROW_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    CARRIERHQ_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ENVUE_TELEMATICS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEET_NAV_SYSTEMS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEET_PROFIT_CENTER_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEETBOSS_GPS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEETHOSTER_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEETISTICS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEETLOCATE_GEOTAB_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEETMASTER_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    FLEXPORT_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GEOFORCE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GLOSTONE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GOFLEET_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GOGPS_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GPS_FLEET_FINDER_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GPS_SOLUTIONS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GPS_TRACKING_CANADA_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GRAYBOX_SOLUTIONS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    GRIDLINE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    HIGHPOINT_GPS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    IOTAB_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    LYNX_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ONTRAK_SOLUTIONS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    PENSKE_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    PREPASS_ELD_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    QUALITY_GPS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    RMJ_TECHNOLOGIES_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    RUSH_ENTERPRISES_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    RYDER_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    SAFETY_VISION_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    SHELL_TELEMATICS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    SURECAM_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    T_MOBILE_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    TRANSFLO_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    TRAXXISGPS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    TRUPATH_SYSTEMS_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    VERTRAX_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ZENDUIT_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    HOLMAN_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ENTERPRISE_FLEET_MANAGEMENT_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/geotab_2D",
    LINXUP_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/62D",
    FLEETSHARP_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/62D",

    ACTSOFT_ENCORE_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ADVANTAGE_ASSET_TRACKING_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ARGOS_CONNECTED_SOLUTIONS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ARI_FLEET_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ASSURED_TELEMATICS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ATNT_FLEET_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ATTRIX_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    BADGER_FLEET_SOLUTIONS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    BAR_CODING_CANADA_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    BLUEARROW_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    CARRIERHQ_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ENVUE_TELEMATICS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEET_NAV_SYSTEMS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEET_PROFIT_CENTER_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEETBOSS_GPS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEETHOSTER_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEETISTICS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEETLOCATE_GEOTAB_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEETMASTER_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    FLEXPORT_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GEOFORCE_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GLOSTONE_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GOFLEET_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GOGPS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GPS_FLEET_FINDER_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GPS_SOLUTIONS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GPS_TRACKING_CANADA_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GRAYBOX_SOLUTIONS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    GRIDLINE_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    HIGHPOINT_GPS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    IOTAB_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    LYNX_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ONTRAK_SOLUTIONS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    PENSKE_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    PREPASS_ELD_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    QUALITY_GPS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    RMJ_TECHNOLOGIES_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    RUSH_ENTERPRISES_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    RYDER_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    SAFETY_VISION_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    SHELL_TELEMATICS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    SURECAM_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    T_MOBILE_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    TRANSFLO_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    TRAXXISGPS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    TRUPATH_SYSTEMS_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    VERTRAX_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",
    ZENDUIT_DATABASE_HINT:
      "This is the web address of your Geotab dashboard or any other page. <a href='https://draivn.com/geotab-url' target='_blank'>Learn more</a>",

    OAUTH_CONNECTED_TITLE: "Connected",
    OAUTH_DESCRIPTION:
      "Connect your account for smooth sharing of telematics data",
    OAUTH_REVOKE_TITLE: "Revoke Access",
    OAUTH_OTHER_METHOD_TITLE: "Other authentication method",
    OAUTH_ERROR_TITLE: "Connection error",

    BASE_OAUTH_BUTTON_TITLE: "Establish connection",
    SAMSARA_OAUTH_BUTTON_TITLE: "Connect Samsara account",
    MOTIVE_OAUTH_BUTTON_TITLE: "Connect Motive account",
    TSP_ADD_DESCRIPTION:
      "Add additional ELD/Telematics or Service Providers below if you use multiple",
    TSP_ADD_BUTTON_TITLE: "Add another TSP source",
    REQUEST_TSP_TITLE: "Request a new TSP",
    REQUEST_TSP_SUBTITLE:
      "Please provide the name of your TSP and the associated Account name/ID. This will allow us to support it promptly.",
    REQUEST_TSP_USERNAME_TITLE: "Telematics service provider name",
    REQUEST_TSP_PASSWORD_TITLE: "Your TSP  account name or ID",

    TSP_METHOD_SELECT_BASIC_TITLE: "Let us setup connection for you",

    AZUGA_ACCOUNT_NAME_TITLE: "Enter your Azuga account number below",
    AZUGA_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/azuga_16",
    AZUGA_ACCOUNT_NAME_FIELD_TITLE: "Azuga Account Number",
    WORKWAVE_ACCOUNT_NAME_TITLE: "Enter your Workwave account number below",
    WORKWAVE_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/azuga_16",
    WORKWAVE_ACCOUNT_NAME_FIELD_TITLE: "Workwave Account Number",

    TELETRAC_NAVMAN_ACCOUNT_NAME_TITLE:
      "Enter your Teletrac Navman account name below",
    TELETRAC_NAVMAN_INSTRUCTIONS_URL:
      "help/knowledge_base/connecting_to_tsp/teletrac_19",
    TELETRAC_NAVMAN_ACCOUNT_NAME_FIELD_TITLE: "Teletrac Navman Account Name",

    ZONAR_ACCOUNT_NAME_TITLE: "Enter your Zonar account number below",
    ZONAR_INSTRUCTIONS_URL: "help/knowledge_base/connecting_to_tsp/geotab_2D",
    ZONAR_ACCOUNT_NAME_FIELD_TITLE: "Zonar Account Number",
    ZONAR_BANNER_TEXT:
      "Note: After completing the onboarding, your service provider will contact you to approve the connection. Data sharing will only begin once this approval is granted.",

    AZUGA_ELD_INSTRUCTIONS_TITLE:
      "Enter your Azuga ELD (fka Vistracks) username and password",
    INTELLISHIFT_INSTRUCTIONS_TITLE:
      "Enter your Intellishift/VTS Silent Passenger username and password below",

    EZ_FLEET_ACCOUNT_NAME_TITLE:
      "Enter your EZ Fleet Tracking/Global Cloud Fleet account or your company name below",
    EZ_FLEET_ACCOUNT_NAME_FIELD_TITLE: "Your Account Name/Company Name",
    EZ_FLEET_BANNER_TEXT:
      "Note: After completing the onboarding, your service provider will contact you to approve the connection. Data sharing will only begin once this approval is granted.",
  },
  COMPLETE: {
    TITLE: "Congratulations!",
    MAIL_NOTIFY_INFO: "We will notify your about further steps via email.",
    SUBTITLE:
      "You have submitted your fleet information and it will be available in your Draivn Visibility account soon.",
  },
};
